import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpRequestService } from 'src/app/core/services';
import {
  NzFormatEmitEvent,
  NzTreeComponent,
  NzTreeNodeOptions,
} from 'ng-zorro-antd/tree';
import { da } from 'date-fns/locale';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzNotificationComponent, NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'],
})
export class PermissionComponent implements OnInit {
  @Input() facultyId = '';
  @Input() from = '';
  @ViewChild('nzTreeComponent', { static: false })
  nzTreeComponent!: NzTreeComponent;
  defaultCheckedKeys = ['10020'];
  defaultSelectedKeys = ['10010'];
  defaultExpandedKeys = ['100', '1001'];
  permissionUrl = 'permissions';

  nodes: NzTreeNodeOptions[] = [];
  loading = false;
  submitLoading = false;
  constructor(
    private httpRequestService: HttpRequestService,
    private drawerRef: NzDrawerRef,
    private notificationService: NzNotificationService,
  ) {}

  ngOnInit(): void {
    if (this.from === 'institute'){
      this.permissionUrl = this.permissionUrl + '/institute/pr';
    }else if (this.from === 'college') {
      this.permissionUrl = this.permissionUrl + '/college/pr';
    }
    this.getPermission();
  }

  getPermission(): void{
    this.loading = true;
    this.httpRequestService
      .request('get', this.permissionUrl)
      .subscribe(async (result: any): Promise<void> => {
        const data = result.data;
        const modules = Object.keys(data);
        const finalData: any = [];

        modules.forEach((module: any) => {
          const tempData = {
            title: module.toUpperCase(),
            key: module,
            frontendAccessKey: data[module].frontendAccessKey,
            children: [],
          };
          const tempChindrenData: any = [];
          data[module].availableOperations.forEach((element: any) => {
            tempChindrenData.push({
              title: element,
              key: element,
              isLeaf: true,
            });
          });
          tempData.children = tempChindrenData;
          finalData.push(tempData);
        });

        try {
          const permissionDataForUser = await this.getPermissionsByUser();
          finalData.forEach((node: any) => {
            const foundItem = permissionDataForUser.data.modules.find(
              (x: any) => x.module === node.key
            );
            if (foundItem) {
              node.children.forEach((child: any) => {
                child.checked = foundItem.permittedOperations.includes(
                  child.key
                );
              });
              node.checked = node.children.every((x: any) => x.checked);
              node.expanded = true;
            }
          });
        } catch (e) {}

        this.nodes = finalData;
        this.getPermissionsByUser();

        this.loading = false;
      });
  }

  async getPermissionsByUser(): Promise<any>{
    return this.httpRequestService
      .request('get', `permissions/${this.facultyId}`)
      .toPromise();
  }

  submitPermission(): void{
    this.submitLoading = true;

    const finalDataArr = this.nodes
      .filter((x: any) => x.children.some((item: any) => item.checked))
      .map((x: any) => ({
        module: x.key,
        frontendAccessKey: x.frontendAccessKey,
        permittedOperations: x.children
          .filter((item: any) => item.checked)
          .map((operation: any) => operation.key),
      }));
    this.httpRequestService
      .request('put', `permissions/${this.facultyId}`, finalDataArr)
      .subscribe((result: any) => {
        this.submitLoading = false;
        this.notificationService.success('Permission Updated', '');
        this.drawerRef.close();
      });
  }
}
