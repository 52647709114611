<div>
    <nz-upload
                class="avatar-uploader"
                nzName="avatar"
                [nzShowUploadList]="false"
                [nzBeforeUpload]="beforeVideoUpload"
                (nzChange)="
                handleUploadManualImage($event, 'explanationVideo')
                "
                [nzAction]="mediaUploadUrl"
                [nzHeaders]="customRequestHeaders"
                [nzName]="'file'"
                [nzAccept]="'image/jpeg, image/jpg, image/png, image/JPEG'"
              >
                <ng-container>
                  <button nz-button>
                    <i nz-icon [nzType]="imageLoading ? 'loading' : ''"></i>
                    Click to Upload
                  </button>
                </ng-container>                
              </nz-upload>
              <div *ngIf="imageUrl" style="margin-top:7px; padding:5px; border:1px solid #e2e2e2; margin-bottom:5px;    background-color: #f9f9f9;">
                {{getTheImagePath}}
            </div>
            <button *ngIf="imageUrl" nz-button [nzSize]="'default'" nzType="primary" (click)="copyTheUrl(getTheImagePath)" >Copy Image Path <i nz-icon nzType="copy" nzTheme="outline"></i></button>
</div>
