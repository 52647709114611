import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { ConfigurationService, HttpRequestService } from 'src/app/core/services';

@Component({
  selector: 'app-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss']
})
export class MediaUploadComponent implements OnInit {
  buttonLoading = false;
  imageLoading = false;
  imageUrl = '';
  avatarUrl = '';
  mediaUploadUrl: any = '';
  getTheImagePath: any = '';
  constructor(
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private msg: NzMessageService,
    private configurationService: ConfigurationService,
  ) {
    this.mediaUploadUrl = configurationService.apiUrl + '/api/media';
  }

  ngOnInit(): void {
  }

  /** Media */
  customRequestHeaders = () => {
    return { Authorization: `Bearer ${localStorage.getItem('token')}` };
  }

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      callback(reader.result ? reader.result.toString() : '')
    );
    reader.readAsDataURL(img);
  }

  beforeVideoUpload = (
    file: NzUploadFile,
    fileList: NzUploadFile[]
  ): Observable<any> => {
    return new Observable((observer: Observer<boolean>) => {
      const isImage = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/JPEG';
      if (!isImage) {
        this.msg.error('You can only upload image file!');
        observer.complete();
        return;
      }
      const isLt2M = file.size ? file.size / 1024 / 1024 < 5 : false;
      if (!isLt2M) {
        this.msg.error('Image must smaller than 5MB!');
        observer.complete();
        return;
      }
      observer.next(isImage && isLt2M);
      observer.complete();
    });
  }

  handleUploadManualImage(
    info: { file: NzUploadFile },
    uploadFor: string
  ): void {
    switch (info.file.status) {
      case 'uploading':
        this.imageLoading = true;
        break;
      case 'done':
        this.getTheImagePath = this.configurationService.mediaBaseUrl + info.file.response.data.path;
        // Get this url from response in real world.
        if (info.file && info.file.originFileObj) {
          this.getBase64(info.file.originFileObj, (image: string) => {
            this.imageLoading = false;
            this.imageUrl = image;
          });
        }
        break;
      case 'error':
        this.msg.error('Network error');
        break;
    }
  }

  copyTheUrl(val: string): void{
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.msg.success('URL Copied');
  }

}
