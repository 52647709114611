<nz-header>
  <div class="app-header">
    <div nz-row>  
      <div nz-col nzSpan="8">
        <div class="logo">
          <img src="assets/images/logo.png" alt="Own Prep" class="sidebarLogo">
         
        </div>
        <div class="collapscnzone">
          <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
          (click)="onChangeCollapse()"></i>
        </div>
      </div>    
      <div nz-col nzSpan="16" class="rightPart">
        <!-- <div>
          <button nz-button nzType="link">
            <i nz-icon nzType="setting" nzTheme="outline" class="header-icon"></i>
          </button>          
        </div>
        <div>
          <button nz-button nzType="link">
            <i nz-icon nzType="bell" nzTheme="outline" class="header-icon"></i>
          </button>          
        </div> -->
        <div>
          <a [routerLink]="['/main/profile']">           
            <i nz-icon nzType="user" nzTheme="outline" class="header-icon"></i><span>{{user.firstName}}</span>
          </a>   
        </div>
        <div>          
          <button nz-button nzType="link" (click)="logout()">
            <i nz-icon nzType="logout" nzTheme="outline" class="header-icon"></i>
          </button>  
                
        </div>
      </div>
    </div>
  </div>
</nz-header>
