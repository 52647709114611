import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {

  transform(source: any[] = [], conditionArr: any[] = [], key: string = '_id'): any[] {
    return source.filter(x => !conditionArr.map(item => item[key]).includes(x[key]));
  }

}
