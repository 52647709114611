<nz-spin [nzSpinning]="loading">
  <nz-tree
    #nzTreeComponent
    [nzData]="nodes"
    nzCheckable
    [nzCheckedKeys]="defaultCheckedKeys"
    [nzExpandedKeys]="defaultExpandedKeys"
    [nzSelectedKeys]="defaultSelectedKeys"
  ></nz-tree>
  <nz-divider *ngIf="nodes.length"></nz-divider>
  <div class="submit-button">
    <button
      nz-button
      [nzType]="'primary'"
      [nzLoading] ="submitLoading"
      (click)="submitPermission()"
      *ngIf="nodes.length"
    >
      Submit
    </button>
  </div>
</nz-spin>
