<nz-layout class="app-layout">
  <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" nzBreakpoint="md" [nzTrigger]="null" nzWidth="250px"  nzTheme="light">
    <app-sidebar [isCollapsed]="isCollapsed"></app-sidebar>
  </nz-sider>
  <nz-layout>
    <app-header (collapsed)="onCollapseChange($event)"></app-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <nz-footer>
      <app-footer></app-footer>
    </nz-footer>
  </nz-layout>
</nz-layout>
